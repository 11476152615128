import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Userlogo from '../../../assets/User-logo.svg';
import UserlogoActive from '../../../assets/user-active-logo.svg';
import Homelogo from '../../../assets/home-logo.svg';
import HomelogoActive from '../../../assets/home-active-logo.svg';
import Monitoringlogo from '../../../assets/monitoring-logo.svg';
import MonitoringlogoActive from '../../../assets/monitoring-active-logo.svg';
import Trackinglogo from '../../../assets/tracking-logo.svg';
import TrackinglogoActive from '../../../assets/tracking-active-logo.svg';
import styles from './SideNav.module.css';
import CustomerSupportActive from '../../../assets/cs-active.svg';
import CustomerSupportInActive from '../../../assets/cs-inactive.svg';
 
const SideNav = () => {
  const location = useLocation();
 
  const isPathActive = (basePath) => location.pathname === basePath || location.pathname.startsWith(basePath + '/');
 
  return (
    <div className={styles.sidebar}>
      <NavLink to="/home" className={({ isActive }) => (isActive ? styles.active : '')}>
        <img src={location.pathname === '/home' ? HomelogoActive : Homelogo} alt="Home" className={styles.customIcon} />
      </NavLink>
 
      <NavLink to="/userOnboarding" className={({ isActive }) => (isActive ? styles.active : '')}>
        <img
          src={isPathActive('/userOnboarding') ? TrackinglogoActive : Trackinglogo}
          alt="User Onboarding"
          className={styles.customIcon}
        />
      </NavLink>
 
      <NavLink to="/faqdocument" className={({ isActive }) => (isActive ? styles.active : '')}>
        <img
          src={isPathActive('/faqdocument') ? MonitoringlogoActive : Monitoringlogo}
          alt="FAQ Document"
          className={styles.customIcon}
        />
      </NavLink>
 
      <NavLink to="/userVerification" className={({ isActive }) => (isActive ? styles.active : '')}>
        <img
          src={isPathActive('/userVerification') ? UserlogoActive : Userlogo}
          alt="User Verification"
          className={styles.customIcon}
        />
      </NavLink>

      <NavLink to="/customerSupport" className={({ isActive }) => (isActive ? styles.active : '')}>
        <img
          src={isPathActive('/customerSupport') ? CustomerSupportActive : CustomerSupportInActive}
          alt="Customer Support"
          className={styles.customIcon}
        />
      </NavLink>
    </div>
  );
};
 
export default SideNav;