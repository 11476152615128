import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../../Components/Common/Title/Title";
import CircleWithText from "../../../Components/CircleWithText/CircleWithText";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import CustomInputField from "../../../Components/CustomInputField/CustomInputField";
import style from "./UserDetails.module.css";
import IdentifictionDocument from "../../../assets/IdentificationDocument.svg";
import AddressInformation from "../../../assets/AddressInformation.svg";
import GreenStep from "../../../assets/GreenStep.svg";
import CrossStep from "../../../assets/CrossStep.svg";
import IDCPDocument from "../../../assets/IDCPDocument.svg";
import Divider from "@mui/material/Divider";
import axios from "axios";
import TextVerifiedIcons from "../Components/TextVerifiedIcons/TextVerifiedIcons";
import OutlinedButtonWithIcon from "../Components/OutlinedButtonwithIcon/OutlinedButtonWithIcon";
import Delete from "../../../assets/delete.svg";
import Reupload from "../../../assets/reupload.svg";
import Snackbar from "@mui/material/Snackbar";
import validate from "../../../assets/validate.svg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";
import AssignCard from "../AssignCard";

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [imageError, setImageError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [personalDocVerif, setPersonalDocVerif] = React.useState(false);
  const [addressDocVerif, setAddressDocVerif] = React.useState(false);
  const [idcpDocVerif, setIdcpDocVerif] = React.useState(false);
  const [userDataa, setUserDataa] = useState(null);
  const [idDoc, setIddoc] = useState(null);
  const [idAddressInfo, setIdAddressInfo] = useState(null);
  const [idcpDoc, setIdcpDoc] = useState(null);
  const [imageloading, setImageloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({
    personalInformation: {
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      countryCode: "",
      number: "",
      dateOfBirth: "",
      countryOfBirth: "",
    },
    addressInformation: {
      addressLine: "",
      cityName: "",
      state: "",
      country: "",
      zipCode: "",
    },
    identificationDocument: {
      documentIssuedCountry: "",
      documentType: "",
      documentNumber: "",
    },
  });
  const [opendail, setOpendail] = React.useState(false);
  const [isPersonalInfoVerified, setIsPersonalInfoVerified] = useState(true);
  const [isAddressVerified, setIsAddressVerified] = useState(true);
  const [isDocumentInfoVerified, setIsDocumentInfoVerified] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [isCardAssigned, setIsCardAssigned] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);


  // const [personaldoc, setPersonaldoc] = useState(null);
  // const [addressdoc, setAddressDataa] = useState(null);
  // const [idcpDoc, setIdcpDoc] = useState(null);




  const [message, setMessage] = useState("");
  const steps = [
    "Personal Information",
    "Address Information",
    "Identification Documents",
  ];

  const validationRules = {
    personalInformation: {
      firstName: { required: true, pattern: /^[a-zA-Z ]*$/ },
      middleName: { required: false, pattern: /^[a-zA-Z ]*$/ },
      lastName: { required: true, pattern: /^[a-zA-Z ]*$/ },
      gender: { required: true },
      countryCode: { required: true, pattern: /^\+\d+$/ },
      number: { required: true, pattern: /^\d{7}$/ },
      dateOfBirth: { required: true },
      countryOfBirth: { required: false, pattern: /^[a-zA-Z ]*$/ },
    },
    addressInformation: {
      addressLine: { required: true },
      cityName: { required: true, pattern: /^[a-zA-Z ]*$/ },
      state: { required: true, pattern: /^[a-zA-Z ]*$/ },
      country: { required: true, pattern: /^[a-zA-Z ]*$/ },
      zipCode: { required: true, pattern: /^\d{5,6}$/ },
    },
    identificationDocument: {
      documentIssuedCountry: { required: true },
      documentType: { required: true },
      documentNumber: { required: true, pattern: /^[A-Z0-9]{6,12}$/ },
    },
  };

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    fetchUserData();
  }, [userId]);

  useEffect(() => {
    checkinfoVerificationStatus();
  }, [userDataa]);

  useEffect(() => {
    axios
      .get(`https://bepay-prod.aifalabs.com/api/be-bot/usercard/${userId}`)
      .then((response) => {
        setIsCardAssigned(response.data.status);
      })
      .catch((error) => { });
  }, [userId]);

  const handleClickOpen = () => {
    setOpendail(true);
  };

  const handleClose = () => {
    setOpendail(false);
  };
  const handleNext = async (next = "") => {
    try {
      const ContinueFunc = () => {
        // Submit the form data
        handleSave();

        // Move to the next step if "next" is passed
        if (next === "next") {
          setCurrentStep((prevStep) =>
            Math.min(prevStep + 1, steps.length - 1)
          );
        } else {
          handleToastMessage("Data saved successfully!");
        }
      };
      if (currentStep === 0) {
        const personalValidation = validateSection(
          formData.personalInformation,
          personalInformationRules,
          "Personal Information"
        );
        if (personalValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(personalValidation.message);
      } else if (currentStep === 1) {
        const addressValidation = validateSection(
          formData.addressInformation,
          addressInformationRules,
          "Address Information"
        );
        if (addressValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(addressValidation.message);
      } else if (currentStep === 2) {
        const documentValidation = validateSection(
          formData.identificationDocument,
          identificationDocumentRules,
          "Identification Document"
        );
        if (documentValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(documentValidation.message);
      }
    } catch (error) {
      handleToastMessage("An error occurred. Please try again.");
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const validateInput = (value, validationRules) => {
    if (validationRules.required && !value.trim()) {
      return "This field is required.";
    }
    if (validationRules.minLength && value.length < validationRules.minLength) {
      return `Minimum length is ${validationRules.minLength} characters.`;
    }
    if (validationRules.pattern && !validationRules.pattern.test(value)) {
      return "Invalid format.";
    }
    return ""; // No errors
  };

  const validateSection = (sectionData, validationRules, sectionName) => {
    for (const field in validationRules) {
      const value = sectionData[field] || "";
      const error = validateInput(value, validationRules[field]);
      if (error) {
        return {
          status: false,
          message: `${sectionName}: ${field} - ${error}`,
        };
      }
    }
    return { status: true, message: `${sectionName} is valid.` };
  };

  const personalInformationRules = {
    firstName: { required: true, pattern: /^[a-zA-Z ]*$/ },
    middleName: { pattern: /^[a-zA-Z ]*$/ },
    lastName: { required: true, minLength: 2, pattern: /^[a-zA-Z ]*$/ },
    gender: { required: true, pattern: /^[a-zA-Z ]*$/ },
    //countryCode: { required: true, /*pattern: /^[+][0-9]{1,3}$/*/ },
    number: { required: true, pattern: /^[0-9]{7}$/ },
    dateOfBirth: { required: true },
    countryOfBirth: { pattern: /^[a-zA-Z ]*$/ },
  };

  const addressInformationRules = {
    addressLine: { required: true },
    cityName: { required: true, pattern: /^[a-zA-Z ]*$/ },
    state: { required: true, pattern: /^[a-zA-Z ]*$/ },
    country: { required: true, pattern: /^[a-zA-Z ]*$/ },
    zipCode: { required: true, pattern: /^[0-9]{6}$/ },
  };

  const identificationDocumentRules = {
    documentIssuedCountry: { required: true },
    documentType: { required: true },
    documentNumber: { required: true, minLength: 5 },
  };

  // Get ID Image
  const postGetIdentificationDocImage = async (data, imageType) => {
    const apiUrl = "https://bepay-prod.aifalabs.com/api/be-bot/onboarding/image";

    if (imageType === "IdentifiactionDoc") {
      setIddoc(null);
    } else if (imageType === "AddressInfo") {
      setIdAddressInfo(null);
    } else {
      setIdcpDoc(null);
    }

    const ImageBlob = () => {
      if (imageType === "IdentifiactionDoc") {
        // changed api return data.personalInformation.url.value;
        return data.personalInformation.url.value;
      } else if (imageType === "AddressInfo") {
        return data.addressInformation.url.value;
      } else {
        return data.identificationDocument.url.value;
      }
    };

    const requestBody = {
      //no need to send latest update container: data.container,
      // container: data.container,
      blob: ImageBlob(),
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      // throw new Error("Failed to fetch the image");
    }

    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    if (imageType === "IdentifiactionDoc") {
      setIddoc(imageUrl);
    } else if (imageType === "AddressInfo") {
      setIdAddressInfo(imageUrl);
    } else {
      setIdcpDoc(imageUrl);
    }
  };

  // //File Reupload
  // const handleOpenFile = (Type) => {
  //   console.log("fileType", Type);
  //   setFileType(Type);
  //   setAnchorEl(null); // Close the popup
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click(); // Programmatically click the file input
  //   }
  // };
  const handleFileChange = async (event, underUpload) => {
    setImageloading(true)
    const selectedFile = event.target.files[0];
    setTimeout(async () => {
      try {
        if (selectedFile) {
          await handleSubmit(selectedFile, underUpload);
          setImageloading(false)

        }
      } catch (error) {
        console.log(error)
        setImageloading(false)

      }
    }, 1000);
  };

  const handleSubmit = async (file, uploadunder) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_upload_under", uploadunder);

    try {
      const response = await axios.put(
        `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/update/document/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        handleToastMessage("File Uploaded");
        fetchUserData();
      }
    } catch (error) {
      handleToastMessage("File Upload Failed");
    } finally {
      setIsUploading(false); // Reset uploading status
    }
  };

  // //File Upload
  // async function handleUploadFile(file) {
  //   console.log("fileType", fileType);
  //   const formFileData = new FormData();
  //   formFileData.append("file", file); // Append the selected file to FormData
  //   formFileData.append("document_upload_under", fileType);
  //   for (const [key, value] of formFileData.entries()) {
  //     console.log(`${key}:`, value);
  //   }
  //   try {
  //     const response = await axios.put(
  //       `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/update/document/${userId}`,
  //       formFileData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data", // Set content type for file upload
  //         },
  //       }
  //     );
  //     console.log("File uploaded successfully:", response.data);
  //     handleToastMessage("File Uploaded");
  //     fetchUserData(true);
  //     setFileInputKey(Math.random());
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     handleToastMessage("File Upload Failed");
  //     setFileInputKey(Math.random());
  //   }
  // }

  //File delete
  const handleDelete = (Type) => {
    const payload = {
      isvalid: false,
      isdeleted: true,
      document_upload_under: Type,
    };
    axios
      .put(
        `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/document/validate/${userId}`,
        payload
      )
      .then((response) => {
        handleToastMessage("File Deleted");
        fetchUserData(true);
      })
      .catch((error) => {
        handleToastMessage("File Delete Failed");
      });
  };

  //File Validate
  const handleValidate = (Type) => {
    const payload = {
      isvalid: true,
      isdeleted: false,
      document_upload_under: Type,
    };
    axios
      .put(
        `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/document/validate/${userId}`,
        payload
      )
      .then((response) => {
        handleToastMessage("File Validated");

        if (Type === "personalInformation") {
          setPersonalDocVerif(true);
        } else if (Type === "addressInformation") {
          setAddressDocVerif(true);
        } else if (Type === "identificationDocument") {
          setIdcpDocVerif(true);
        }

        checkinfoVerificationStatus();
      })
      .catch((error) => {
        handleToastMessage("File Validation Failed");
      });
  };

  function formatDate(inputValue) {
    const padWithZero = (value) => value.padStart(2, "0");
    if (inputValue.includes("-")) {
      // const [day, month, year] = inputValue.split("-");
      // return `${month}-${day}-${year}`;
      return inputValue;
    }

    if (inputValue.includes(".")) {
      const [day, month, year] = inputValue.split(".");
      return `${year}-${padWithZero(month)}-${padWithZero(day)}`;
    }

    throw new Error("Invalid date format");
  }

  //  fetch user Data
  const fetchUserData = async (xyz = false) => {
    try {
      setImageloading(true);
      const response = await fetch(
        `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const result = await response.json();
      const data = result.data;
      console.log("init--->", data.personalInformation.dateOfBirth.value);
      console.log(
        "init-formatted--->",
        formatDate(data.personalInformation.dateOfBirth.value)
      );
      setFormData({
        personalInformation: {
          firstName: data.personalInformation.firstName.value || "",
          middleName: data.personalInformation.middleName.value || "",
          lastName: data.personalInformation.lastName.value || "",
          gender: data.personalInformation.gender.value || "",
          countryCode: data.personalInformation.countryCode.value || "",
          number:
            removeCountryCode(data.personalInformation.number.value) || "",
          // dateOfBirth: data.personalInformation.dateOfBirth.value || "",
          dateOfBirth:
            formatDate(data.personalInformation.dateOfBirth.value) || "",
          countryOfBirth: data.personalInformation.countryOfBirth.value || "",
        },
        addressInformation: {
          addressLine: data.addressInformation.addressLine.value || "",
          cityName: data.addressInformation.cityName.value || "",
          state: data.addressInformation.state.value || "",
          country: data.addressInformation.country.value || "",
          zipCode: data.addressInformation.zipCode.value || "",
        },
        identificationDocument: {
          documentIssuedCountry:
            data.identificationDocument.documentIssuedCountry.value || "",
          documentType: data.identificationDocument.documentType.value || "",
          documentNumber:
            data.identificationDocument.documentNumber.value || "",
        },
      });
      setUserDataa(data);
      postGetIdentificationDocImage(data, "IdentifiactionDoc");
      postGetIdentificationDocImage(data, "AddressInfo");
      postGetIdentificationDocImage(data, "IDCPDoc");

      //manage verified or not.....

      setPersonalDocVerif(data.personalInformation.url.status);
      setAddressDocVerif(data.addressInformation.url.status);
      setIdcpDocVerif(data.identificationDocument.url.status);
      setImageError(false)
    } catch (error) {
    } finally {
      setImageloading(false);
      setImageError(false)
    }
  };

  const removeCountryCode = (phoneNumber) => {
    if (phoneNumber.startsWith("+592")) {
      // Remove the first 4 characters (+592)
      return phoneNumber.slice(4);
    }
    return phoneNumber;
  };
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleToastMessage = (messageText) => {
    setMessage(messageText);
    setOpen(true);
  };

  const action = <React.Fragment></React.Fragment>;

  // Handle Verification check and change the icons
  const checkinfoVerificationStatus = () => {
    if (!userDataa) return;

    let personalInfoVerified = true;
    let addressVerified = true;
    let documentInfoVerified = true;

    for (const [key, value] of Object.entries(userDataa.personalInformation)) {
      if (value["status"] !== undefined && value["status"] !== undefined) {
        if (value["status"] === false) {
          personalInfoVerified = false;
          break;
        }
      }
    }

    // Check address verification
    for (const [key, value] of Object.entries(
      userDataa.addressInformation || {}
    )) {
      if (value.status === false) {
        addressVerified = false;
        break;
      }
    }

    // Check document information verification
    for (const [key, value] of Object.entries(
      userDataa.identificationDocument || {}
    )) {
      if (value?.status === false) {
        documentInfoVerified = false;
        break;
      }
    }

    setIsPersonalInfoVerified(personalInfoVerified);
    setIsAddressVerified(addressVerified);
    setIsDocumentInfoVerified(documentInfoVerified);
  };

  const handleInputChange = (section, name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [name]: value,
      },
    }));
  };

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  // patch API work to update the specific field
  const patchUserData = async (updatedFieldPath, updatedValue) => {
    try {
      const payload = { [updatedFieldPath.split(".").pop()]: updatedValue };
      const response = await axios.patch(
        `http://localhost:8080/api/user/${userId}`,
        payload
      );
      if (response.status === 200) {
        // Update the relevant field in userDataa
        const updatedUserData = { ...userDataa };
        const fieldPathArray = updatedFieldPath.split(".");
        let current = updatedUserData;

        // Traverse to the relevant part of the object
        fieldPathArray.forEach((key, index) => {
          if (index === fieldPathArray.length - 1) {
            current[key] = {
              ...current[key],
              value: updatedValue,
              verified: 1,
            };
          } else {
            current[key] = { ...current[key] };
            current = current[key];
          }
        });
        setUserDataa(updatedUserData);

        checkinfoVerificationStatus();
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  if (!userDataa) {
    return <div>Loading...</div>;
  }

  const handleStepChange = (stepIndex) => {
    if (isEditing) {
      setCurrentStep(stepIndex);
    } else {
      handleToastMessage("Please Submit or Close the Active text Field");
    }
  };

  // Save
  const handleSave = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    // handleClickOpen();

    axios
      .put(
        `https://bepay-prod.aifalabs.com/api/be-bot/onboarding/${userId}`,
        formData
      )
      .then((response) => {
        // alert("Data submitted successfully!");
        // navigate(-1);
        // handleClickOpen();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleAppRej = (status) => {
    handleSave();
    handleSubmitReject(status);
  };

  // Submit/Reject
  const handleSubmitReject = (status) => {
    axios
      .put(
        ` https://bepay-prod.aifalabs.com/api/be-bot/onboarding/status/${userId}`,
        { status: status }
      )
      .then((response) => {
        if (
          response.status &&
          status === "Approve" &&
          isCardAssigned === false
        ) {
          setCurrentStep(3);
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const DocumentWithImageText = (props) => {
    return (
      <Grid size={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
          }}
        >
          <TextVerifiedIcons title={props.text} isVerified={props.isVerified} />
          {imageloading ? (
            <div className={style["image-container"]}>
              <CircularProgress />
            </div>
          ) : props.document && props.text ? (
            <div className={style["image-container"]}>
              {!imageError ? (
                <img
                  src={props.document}
                  onError={() => setImageError(true)}
                />
              ) : (
                <p>Please reupload the file</p>
              )}
            </div>
          ) : null}

          <div className={style["button-container"]}>
            <label
              htmlFor="file-upload"
              className="custom-file-upload"
              style={{
                border: "2px solid",
                color: "#2467F5",
                borderRadius: "16px",
                display: "flex",
                padding: "0px 10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                <span>
                  <img src={Reupload} />{" "}
                </span>
              }
              <span>{"Reupload"}</span>
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={(e) => handleFileChange(e, props.OnReupload)}
              disabled={isUploading}
              style={{ display: "none" }} // Hides the default file input
            />
            {/* <input
              type="file"
              onChange={(e) => handleFileChange(e, props.OnReupload)}
              disabled={isUploading} // Disable input during upload
            /> */}
            {/* <OutlinedButtonWithIcon
              text={"Reupload"}
              icon={Reupload}
              color="#2467F5"
              borderColor="#2467F5"
              className="button"
              onClick={props.OnReupload}
            /> */}
            <OutlinedButtonWithIcon
              text={"Delete"}
              icon={Delete}
              color="#B93E3E"
              borderColor="#B93E3E"
              className="button"
              onClick={props.OnDelete}
            />
            <OutlinedButtonWithIcon
              text={"Validate"}
              icon={validate}
              color="#1F8F1F"
              borderColor="#1F8F1F"
              className={`${style["third-button"]} button`}
              onClick={props.OnValidate}
            />
          </div>
        </div>
      </Grid>
    );
  };

  const ButtonsSection = () => {
    return (
      <Grid size={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {currentStep > 0 && currentStep!==3 &&(
            <button onClick={handleBack} className={style["back-button"]}>
              Back
            </button>
          )}

          {currentStep < steps.length - 1 && (
            <>
              <button
                onClick={() => handleNext()}
                className={style["back-button"]}
              >
                Save
              </button>
              <button
                onClick={() => handleNext("next")}
                className={style["approve-button"]}
              >
                Save & Next
              </button>
            </>
          )}

          {currentStep === 2 && (
            <>
              <button
                onClick={() => handleNext()}
                className={style["back-button"]}
              >
                Save
              </button>
              <button
                className={style["reject-button"]}
                onClick={() => handleAppRej("Reject")}
              >
                Reject
              </button>

              <button
                className={style["approve-button"]}
                onClick={() => handleAppRej("Approve")}
              >
                Approve
              </button>
            </>
          )}
        </div>
      </Grid>
    );
  };
  return (
    <div style={{ margin: "0px 30px" }}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />

      <React.Fragment>
        <Dialog
          open={opendail}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              handleClose();
            },
          }}
        >
          <DialogTitle>Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ width: "auto" }}>
              please Enter the Card number.
            </DialogContentText>
            <TextField
              sx={{ width: "300px" }}
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="Card Number"
              type="email"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Cancel</Button> */}
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Title title="User Onboarding" />
      <div
        style={{
          borderRadius: "8px",
          padding: "0px 30px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "40px",
              marginTop: "30px",
              marginBottom: "40px",
            }}
          >
            <CircleWithText
              // callBackFuntion={() => handleStepChange(0)}
              text="Personal <br/>Information"
              color="#ffffff"
              textColor="#fff"
              img={isPersonalInfoVerified ? GreenStep : CrossStep}
              isActive={currentStep === 0}
            />
            <CircleWithText
              // callBackFuntion={() => handleStepChange(1)}
              text="Address<br/> Information"
              color="#ffffff"
              textColor="#fff"
              img={isAddressVerified ? GreenStep : CrossStep}
              isActive={currentStep === 1}
            />
            <CircleWithText
              // callBackFuntion={() => handleStepChange(2)}
              text="Identification<br/> Documents"
              color="#ffffff"
              textColor="#fff"
              img={isDocumentInfoVerified ? GreenStep : CrossStep}
              isActive={currentStep === 2}
            />
            {isCardAssigned === false && (
              <CircleWithText
                // callBackFuntion={() => handleStepChange(3)}
                text="Assign<br/> Card"
                color="#ffffff"
                textColor="#fff"
                img={isDocumentInfoVerified ? GreenStep : CrossStep}
                isActive={currentStep === 3}
              />
            )}
          </div>
          <Grid container spacing={1}>
            <Grid size={currentStep === 3 ? 12 : 6.5}>
              <Grid container spacing={currentStep !== 3 ? 4 : undefined}>
                {currentStep === 0 && (
                  <>
                    <Grid size={4}>
                      {" "}
                      <CustomInputField
                        isVerified={
                          userDataa.personalInformation.firstName?.status
                        }
                        title="First Name"
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "firstName",
                            value
                          )
                        }
                        name={"firstName"}
                        value={formData.personalInformation.firstName}
                        validationRules={{
                          required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "middleName",
                            value
                          )
                        }
                        name={"middleName"}
                        // openToast={()=>{setOpen(false)}}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.middleName?.status
                        }
                        title="Middle Name"
                        value={formData.personalInformation.middleName}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.middle_name",
                            updatedValue
                          )
                        }
                        validationRules={{
                          // required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "lastName",
                            value
                          )
                        }
                        name={"lastName"}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.lastName?.status
                        }
                        title="Last Name"
                        value={formData.personalInformation.lastName}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.last_name",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.gender?.status
                        }
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "gender",
                            value
                          )
                        }
                        name={"gender"}
                        isGender={true}
                        title="Gender"
                        value={formData.personalInformation.gender}
                        options={genderOptions}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.gender",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <CustomInputField
                        isDisabled={true}
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.countryCode?.status
                        }
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "countryCode",
                            value
                          )
                        }
                        name={"countryCode"}
                        title="Country Code"
                        // value={formData.personalInformation.countryCode}
                        value={"+592"}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.country_code",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.number?.status
                        }
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "number",
                            value
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^[0-9]{7}$/,
                        }}
                        name={"number"}
                        title="Phone Number"
                        value={formData.personalInformation.number}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.phone_number",
                            updatedValue
                          )
                        }
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isDateField={true}
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "dateOfBirth",
                            value
                          )
                        }
                        name={"dateOfBirth"}
                        isVerified={
                          userDataa.personalInformation.dateOfBirth?.status
                        }
                        title="Date of Birth"
                        value={formData.personalInformation.dateOfBirth}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.date_of_birth",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          // pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "personalInformation",
                            "countryOfBirth",
                            value
                          )
                        }
                        name={"countryOfBirth"}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.personalInformation.countryOfBirth?.status
                        }
                        title="Country of Birth (optional)"
                        value={formData.personalInformation.countryOfBirth}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "personal_information.country_of_birth",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: false,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <Grid size={12}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.addressInformation.addressLine?.status
                        }
                        title="Address Line"
                        onChange={(name, value) =>
                          handleInputChange(
                            "addressInformation",
                            "addressLine",
                            value
                          )
                        }
                        name={"addressLine"}
                        value={formData.addressInformation.addressLine}
                        multiline
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.addressLine",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                    {/* <Grid size={12}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={address.address_line2?.verified}
                        title="Address Line 2"
                        value={address.address_line2?.value}
                        multiline
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.addressLine",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid> */}
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "addressInformation",
                            "cityName",
                            value
                          )
                        }
                        name={"cityName"}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.addressInformation.cityName?.status
                        }
                        title="City Name"
                        value={formData.addressInformation.cityName}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.cityName",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "addressInformation",
                            "state",
                            value
                          )
                        }
                        name={"state"}
                        isEdittingSubmitted={isEditing}
                        isVerified={userDataa.addressInformation.state?.status}
                        title="State/Province"
                        value={formData.addressInformation.state}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.state",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "addressInformation",
                            "country",
                            value
                          )
                        }
                        name={"country"}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.addressInformation.country?.status
                        }
                        title="Country"
                        value={formData.addressInformation.country}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.country",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^[a-zA-Z ]*$/,
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.addressInformation.zipCode?.status
                        }
                        onChange={(name, value) =>
                          handleInputChange(
                            "addressInformation",
                            "zipCode",
                            value
                          )
                        }
                        name={"zipCode"}
                        title="Zip Code"
                        value={formData.addressInformation.zipCode}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "addressInformation.zipCode",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                          pattern: /^\d{6}(?:[-\s]\d{4})?$/,
                        }}
                      />
                    </Grid>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.identificationDocument.documentIssuedCountry
                            ?.status
                        }
                        title="Document Country of Issue"
                        value={
                          formData.identificationDocument.documentIssuedCountry
                        }
                        name={"documentIssuedCountry"}
                        onChange={(name, value) =>
                          handleInputChange(
                            "identificationDocument",
                            "documentIssuedCountry",
                            value
                          )
                        }
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "identificationDocument.documentIssuedCountry",
                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.identificationDocument.documentType?.status
                        }
                        onChange={(name, value) =>
                          handleInputChange(
                            "identificationDocument",
                            "documentType",
                            value
                          )
                        }
                        name={"documentType"}
                        title="Document Type"
                        value={formData.identificationDocument.documentType}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "identificationDocument.documentType",

                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid size={12}>
                      <CustomInputField
                        onIsEditting={() => handleIsEditing()}
                        handleToastMessage={handleToastMessage}
                        onChange={(name, value) =>
                          handleInputChange(
                            "identificationDocument",
                            "documentNumber",
                            value
                          )
                        }
                        name={"documentNumber"}
                        isEdittingSubmitted={isEditing}
                        isVerified={
                          userDataa.identificationDocument.documentNumber
                            ?.status
                        }
                        title="Document Number"
                        value={formData.identificationDocument.documentNumber}
                        onSubmit={(updatedValue) =>
                          patchUserData(
                            "identificationDocument.documentNumber",

                            updatedValue
                          )
                        }
                        validationRules={{
                          required: true,
                        }}
                      />
                    </Grid>
                  </>
                )}
                {currentStep === 3 && <AssignCard userId={userId} />}
                <ButtonsSection />
                {/* {currentStep !== 3 && <ButtonsSection />} */}
              </Grid>
            </Grid>
            {currentStep !== 3 && (
              <Grid size={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            )}
            {currentStep !== 3 && (
              <Grid size={5}>
                {currentStep === 0 && (
                  <DocumentWithImageText
                    isVerified={personalDocVerif}
                    text="Identification Document"
                    document={idDoc}
                    OnReupload={"personalInformation"} //{() => handleOpenFile("personalInformation")}
                    OnDelete={() => handleDelete("personalInformation")}
                    OnValidate={() => handleValidate("personalInformation")}
                  />
                )}
                {currentStep === 1 && (
                  <DocumentWithImageText
                    isVerified={addressDocVerif}
                    text="Address Information"
                    document={idAddressInfo}
                    OnReupload={"addressInformation"} //{() => handleOpenFile("addressInformation")}
                    OnDelete={() => handleDelete("addressInformation")}
                    OnValidate={() => handleValidate("addressInformation")}
                  />
                )}
                {currentStep === 2 && (
                  <DocumentWithImageText
                    isVerified={idcpDocVerif}
                    text="IDCP Document"
                    document={idcpDoc}
                    OnReupload={"identificationDocument"} //{() => handleOpenFile("identificationDocument")}
                    OnDelete={() => handleDelete("identificationDocument")}
                    OnValidate={() => handleValidate("identificationDocument")}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {/* <ButtonsSection /> */}
          {/* {currentStep === 2 && <ButtonsSection />} */}
        </Box>
      </div>
    </div>
  );
};
export default UserDetailsPage;
