import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import SideNav from "./Components/Common/SideNav/Sidenav";
import Header from "./Components/Common/Header/Header";
import FaqDocument from "./Pages/FaqDocument/FaqDocument";
import UserOnboarding from "./Pages/UserOnboarding/UserOnboarding";
import UserVerification from "./Pages/UserVerification/UserVerification";
import UserDetailsPage from "./Pages/UserOnboarding/UserDetails/UserDetails";
import CustomerSupport from "./Pages/CustomerSupport/CustomerSupport";
import UserVerificationDetails from "./Pages/UserVerification/UserVerificationDetails/UserVerificationDetails";
import LoginPage from "./Pages/Login/LoginPage";

function App() {
  const profileImage = "https://randomuser.me/api/portraits/men/1.jpg";
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    const storedUsername = localStorage.getItem("username");

    if (storedAuth === "true") {
      setIsAuthenticated(true);
      setUsername(storedUsername);
    }
  }, []);

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated ? (
          <>
            <SideNav />
            <div className="main-content">
              <Header
                username={username}
                profileImage={profileImage}
                setIsAuthenticated={setIsAuthenticated}
                setUsername={setUsername}
              />
              <div className="content">
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route path="/faqdocument" element={<FaqDocument />} />
                  <Route path="/userOnboarding" element={<UserOnboarding />} />
                  <Route
                    path="/userVerification"
                    element={<UserVerification />}
                  />
                  <Route
                    path="/customerSupport"
                    element={<CustomerSupport />}
                  />
                  <Route
                    path="/userOnboarding/:userId"
                    element={<UserDetailsPage />}
                  />
                  <Route
                    path="/userVerification/:userId"
                    element={<UserVerificationDetails />}
                  />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <LoginPage
                  setIsAuthenticated={setIsAuthenticated}
                  setUsername={setUsername}
                />
              }
            />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;