// src/pages/UserVerification.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tablecomponent from '../../Components/Common/TableComponent/TableComponent';
import style from './UserVerification.module.css';
import UserVerificationDetails from './UserVerificationDetails/UserVerificationDetails';
import axios from 'axios';

const UserVerification = () => {
  const navigate = useNavigate();
  const [userData, setUserData]=useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [id, setId]=useState(null);
  
  const columns = [
    { field: 'name', headerName: 'User Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email ID', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'cardNumber', headerName: 'Card Number', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastUpdate', headerName: 'Last Update', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'cardAssignedDate', headerName: 'Card Assigned date', flex: 1, headerClassName: 'super-app-theme--header' },
  ];

  const handleRowClick = (params) => {
    console.log('Row clicked:', params.row);    
      navigate(`/userVerification/${params.row.id}`/*, { state: { userData: params.row } }*/);    
  };

  const handleBackToTable = () => {
    setSelectedRow(null); 
  };


  useEffect(() => {
    setLoading(true);
    axios.get('https://bepay-prod.aifalabs.com/api/be-bot/usercard', {
      headers: {
        'Content-Type': 'application/json',          
        'Custom-Header': 'value'                      
      }
    })
      .then(response => {
        console.log(response)
        setUserData(response.data.data);
        setId(response.data.data.userid);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, []);


  const rowss = userData ? userData.map(user => ({
    id: user.userid,
    name: user.username,
    email: user.email,
    cardNumber: user.cardNumber,
    lastUpdate: user.updatedAt,
    cardAssignedDate: user.createdAt,
  })) : []; 
  
  return (
    <div>
      {/* {selectedRow === null ? */}
        <>
          <h1 className={style.title}>User Verification</h1>
          <div className={style["table-style"]}>
            <Tablecomponent rows={rowss} columns={columns} onRowClick={handleRowClick} pageSizeOptions={[11]} initialPageSize={11} tableHeight={573} textColor="#404040" headerBgColor='#FAF9F7' />
          </div>
        </> 
        {/* : <UserVerificationDetails selectedRow={selectedRow} classbackFuntion={handleBackToTable} /> */}
    </div>
  );
};

export default UserVerification;