import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Title from "../../Components/Common/Title/Title";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from './FaqDocument.module.css';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddButton from '../../assets/AddButtton.svg';
import EditButton from '../../assets/EditButton.svg';
import FAQFileUpload from '../../assets/FAQFileUpload.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../Components/Common/FileUpload/FileUpload';
 
 
const FaqDocument = () => {
  const [accordions, setAccordions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
 
  const [file, setFile] = useState(null);
  const [openFileDialog, setOpenFileDialog] = useState(false); // For handling file upload dialog
  const [openDialog, setOpenDialog] = useState(false); // For handling dialog open state
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // For delete confirmation dialog
  const [editingAccordion, setEditingAccordion] = useState(null); // Track which accordion is being edited
  const [newQuestion, setNewQuestion] = useState('');  // State for the question input
  const [newAnswer, setNewAnswer] = useState('');      // State for the answer text area
 
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // State to manage the popup
  const fileInputRef = useRef(null);
 
  const itemsPerPage = 12;
 
  const filteredAccordions = accordions.filter((accordion) =>
    accordion.question.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  const handleCloses = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
 
    setOpen(false);
  };
  const action = (
    <React.Fragment>
    </React.Fragment>
  );
 
  const totalPages = Math.ceil(filteredAccordions.length / itemsPerPage);
 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredAccordions.slice(startIndex, startIndex + itemsPerPage);
 
  const leftAccordions = currentItems.slice(0, 6);
  const rightAccordions = currentItems.slice(6, 12);
 
 
  useEffect(() => {
    handleGetAccordion();
  }, []);
 
 
  // Get Accordian color
  const handleGetAccordion = () => {
    axios.get('https://bepay-prod.aifalabs.com/api/be-bot/faq/dashboard/display', {
      headers: {
        'Content-Type': 'application/json',
        'Custom-Header': 'value'
      }
    })
      .then(response => {
        setAccordions(response.data.data);
        setLoading(false);
      })
      .catch(err => {
        setError('Failed to fetch FAQs');
        setLoading(false);
      });
  }
 
 
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
 
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
 
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the popup at the button
  };
 
  const handleClose = () => {
    setAnchorEl(null); // Close the popup
  };
 
  const handleOpenQuestion = () => {
    setEditingAccordion(null);
    setNewQuestion('');
    setNewAnswer('');
    setOpenDialog(true); // Open the dialog
    setAnchorEl(null);   // Close the popup menu
  };
 
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };
 
  const handleOpenFile = () => {
    setAnchorEl(null); // Close the popup
    setOpenFileDialog(true);
    // if (fileInputRef.current) {
    //   fileInputRef.current.click(); // Programmatically click the file input
    // }
  };
 
  const handleCloseFileDialog = () => {
    setOpenFileDialog(false); // Close the file upload dialog
  };
 
  const handleFileUpload = (file) => {
     setFile(file); // Set the file in the parent state
     handleUploadFile(file); // Trigger file upload function
  };
 
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const validTypes = [
  //       'application/pdf',
  //       'application/msword',
  //       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //     ];
 
  //     if (validTypes.includes(file.type)) {
  //       console.log('Selected file:', file);
  //       handleUploadFile(file);  // Call the upload function if valid
  //     } else {
  //       console.error('Invalid file type. Only PDF and Word documents are allowed.');
  //       alert('Please upload only PDF or Word documents.');
  //     }
  //   }
  // };
 
  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);           // Append the selected file to FormData
    formData.append('uploader_name', 'aashika');  // Append the uploader_name to FormData
    setLoading(true);
    axios.post('https://bepay-prod.aifalabs.com/api/be-bot/faq/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',  // Set content type for file upload
      },
    })
      .then((response) => {
        console.log('File uploaded successfully:', response.data);
        setMessage("FAQ File Added Successful")
        setOpen(true);
        // Optionally, fetch updated FAQs or show a success message
          // Function to fetch updated accordions
        setOpenFileDialog(false);
        setLoading(false);
        handleGetAccordion();
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        setMessage("File Not Added")
        setOpen(true);
        setLoading(false);
        setOpenFileDialog(false);
       
      });
  };
 
 
  const handleOpenDeleteDialog = (accordion) => {
    setEditingAccordion(accordion); // Set the accordion to be deleted
    setOpenDeleteDialog(true);
  };
 
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setEditingAccordion(null);
  };
 
 
  // Get Accordian Delete
 
  const handleDeleteAccordion = () => {
    axios.delete(`https://bepay-prod.aifalabs.com/api/be-bot/faq/delete/${editingAccordion.id}`)
      .then(() => {
        // Remove the accordion from state after deletion
        setAccordions(accordions.filter((accordion) => accordion.id !== editingAccordion.id));
        handleCloseDeleteDialog();
        setMessage("FAQ Delete Successful");
        setOpen(true);
      })
      .catch((error) => {
        console.error('Error deleting accordion:', error);
        setMessage("FAQ Delete Unsuccessful")
        setOpen(true);
        handleCloseDeleteDialog();
      });
  };
 
  const handleEditAccordion = (accordion) => {
    setEditingAccordion(accordion);
    setNewQuestion(accordion.question);
    setNewAnswer(accordion.answer);
    setOpenDialog(true);
  };
 
 
  // Update API
  const handleSaveEdit = () => {
    axios.put(`https://bepay-prod.aifalabs.com/api/be-bot/faq/update/${editingAccordion.id}`, {
      question: newQuestion,
      answer: newAnswer,
    })
      .then((response) => {
        const updatedAccordions = accordions.map((accordion) =>
          accordion.id === editingAccordion.id
            ? { ...accordion, question: newQuestion, answer: newAnswer }
            : accordion
        );
        setAccordions(updatedAccordions);
        handleCloseDialog();
        setMessage("FAQ Edit Successful")
        setOpen(true);
        // alert('Edited Successfully');
      })
      .catch((error) => {
        console.error('Error updating accordion:', error);
        setMessage("FAQ Edit Unsuccessful")
        setOpen(true);
      });
  };
 
 
 
  const handleSave = () => {
    const formattedQuestion = newQuestion.trim().endsWith('?') ? newQuestion : `${newQuestion.trim()}?`;
    axios.post(`https://bepay-prod.aifalabs.com/api/be-bot/faq/add`, {
      question: formattedQuestion,
      answer: newAnswer,
    })
      .then((response) => {
        console.log("response ", response);
        handleCloseDialog();
        handleGetAccordion();
        setMessage("New FAQ Add Successful")
        setOpen(true);
        //  alert('FAQ Added');
      })
      .catch((error) => {
        console.error('Error updating accordion:', error);
        setMessage("New FAQ Add Unsuccessful")
        setOpen(true);
      });
  };
 
 
  return (
    <div className="accordions-page">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: '0px 180px 0px 50px ', alignItems: 'center' }}>
        <Title title="FAQ Document" />
        <div className={style["search-bar"]}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: '300px', float: 'right' }}
          />
          {/* <IconButton
            sx={{ marginLeft: '50px', float: 'right' }}
             // Open popup on click
          > */}
          <img
            src={AddButton}
            alt="Add"
            style={{
              padding: '10px',
              // width: '40px',
              // height: '35px',
              backgroundColor: '#3c66fa',
              borderRadius: '8px',
              cursor: 'pointer',
              marginLeft: '30px',
              float: 'right'
            }}
            onClick={handleAddClick}
          />
          {/* </IconButton> */}
 
          {/* Popup Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenFile} style={{ Padding: '20px', gap: '20px' }}>
              <img src={FAQFileUpload} width='26px' height='26px' />
              Add FAQ File
            </MenuItem>
            <MenuItem onClick={handleOpenQuestion} style={{ Padding: '20px', gap: '20px' }}>
              <img src={EditButton} />
              Add Question
            </MenuItem>
          </Menu>
 
 
          {/* File Upload Dialog */}
          <Dialog open={openFileDialog} onClose={handleCloseFileDialog}>
            <DialogTitle>Upload FAQ File</DialogTitle>
            <DialogContent>
              <FileUpload  onFileUpload={handleFileUpload}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
 
 
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{editingAccordion ? 'Edit Question' : 'Add a New Question'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Question"
                fullWidth
                variant="outlined"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                disabled={editingAccordion}
                sx={{
                  color: editingAccordion ? 'red' : 'black' // Set the text color
                }}
              />
              <TextField
                margin="dense"
                label="Answer"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={newAnswer}
                onChange={(e) => setNewAnswer(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button onClick={editingAccordion ? handleSaveEdit : handleSave} color="primary">
                {editingAccordion ? 'Save' : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
 
          <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Delete this FAQ?</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">No</Button>
              <Button onClick={handleDeleteAccordion} color="secondary">Yes</Button>
            </DialogActions>
          </Dialog>
 
 
          {/* <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          /> */}
        </div>
      </div>
 
 
      {loading ? (
        <div className={style["circular-progress"]}>
          <CircularProgress />
        </div>
      ) : (
        <div className={style["accordions-container"]}>
          <div className={style["left-column"]}>
            {leftAccordions.map((accordion, index) => (
              <Accordion key={index} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  className={style["accordion-summary"]}
                  sx={{ backgroundColor: '#F1F1F1' }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{accordion.question}</Typography>
                  <div className={style["accordion-icons"]}>
                    <IconButton onClick={() => handleEditAccordion(accordion)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(accordion)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{accordion.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div className={style["right-column"]}>
            {rightAccordions.map((accordion, index) => (
              <Accordion key={index} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 6}-content`}
                  id={`panel${index + 6}-header`}
                  className={style["accordion-summary"]}
                  sx={{ backgroundColor: '#F1F1F1' }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{accordion.question}</Typography>
                  <div className={style["accordion-icons"]}>
                    <IconButton onClick={() => handleEditAccordion(accordion)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(accordion)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{accordion.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      )}
      <div className={style["pagination-container"]}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
  );
};
 
export default FaqDocument;