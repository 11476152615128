import React, { useState, useEffect } from "react";
import styles from "./CustomInputField.module.css";
import TextVerifiedIcons from "../../Pages/UserOnboarding/Components/TextVerifiedIcons/TextVerifiedIcons";

const CustomInputField = ({
  isDisabled=false,
  title,
  value,
  onChange,
  multiline,
  placeholder,
  name,
  isVerified = false,
  isDateField = false,
  isGender = false,
  options = [],
  validationRules = {}
}) => {
  const [inputValue, setInputValue] = useState(value); // Local state for input value
  const [errorMessage, setErrorMessage] = useState("");

  // Sync local state with parent value when prop changes
  useEffect(() => {
    setInputValue(value);
    console.log('***>>>..',value);
  }, [value]);

  const validateInput = (value) => {
    if (validationRules.required && !value.trim()) {
      return "This field is required.";
    }
    if (validationRules.minLength && value.length < validationRules.minLength) {
      return `Minimum length is ${validationRules.minLength} characters.`;
    }
    if (validationRules.pattern && !validationRules.pattern.test(value)) {
      return "Invalid format.";
    }
    return "";
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (onChange) {
      onChange(name, newValue); 
    }

    const validationError = validateInput(newValue);
    setErrorMessage(validationError);
  };

  return (
    <div className={styles.customInput}>
      <TextVerifiedIcons title={title} isVerified={isVerified} />
      <div className={styles.inputContainer}>
        {multiline ? (
          <textarea
            placeholder={placeholder || "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            className={styles.input}
          />
        ) : isGender ? (
          <select
            value={inputValue}
            onChange={handleInputChange}
            className={styles.input}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value} selected={option.value.toLowerCase()===inputValue.toLowerCase()?true:false}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={isDateField ? "date" : "text"}
            placeholder={placeholder || "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            disabled={isDisabled}
            max={isDateField ? new Date().toISOString().split("T")[0] : undefined} 
            className={`${styles.input} ${errorMessage ? styles.error : ""}`}
          />
        )}
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default CustomInputField;
