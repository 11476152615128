import React, { useState } from "react";
import {
  CircularProgress,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import emptycard from "../../assets/emptyCard.png";
import Grid from "@mui/material/Grid2";
import styles from "./AssignCard.module.css";
import Snackbar from "@mui/material/Snackbar";

const AssignCard = ({ userId }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false); // Control loading spinner state
  const [cardNumber, setCardNumber] = useState(""); // Card number state
  const [showImage, setShowImage] = useState(false); // To control when to display the image
  const [error, setError] = useState(""); // Track validation error message
  const [message, setMessage] = useState("");
  // Get today's date in MM/DD/YY format
  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Ensure day has 2 digits
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Ensure month has 2 digits
    const year = today.getFullYear().toString().slice(2); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleToastMessage = (messageText) => {
    setMessage(messageText);
    setOpen(true);
  };

  const action = <React.Fragment></React.Fragment>;
  const handleCardUpload = () => {
    if (cardNumber.length !== 12 || isNaN(cardNumber)) {
      setError("Card number must be exactly 12 digits.");
      return; // Prevent further execution if validation fails
    }

    setLoading(true); // Start the loading spinner
    setShowImage(false); // Hide the image initially
    setError(""); // Clear error if validation passed
    console.log("userId ", userId);
    axios
      .post(`https://bepay-prod.aifalabs.com/api/be-bot/usercard`, {
        cardNumber: cardNumber,
        userid: userId,
      })
      .then((response) => {
        console.log("response ", response);
        if(response.status===false && response.message==='Card already generated.'){
         // alert(response.message);
          handleToastMessage(response.message);
        }else{
          handleToastMessage("Card Details added");
        }
        setTimeout(() => {
          setLoading(false); // Stop the spinner
          setShowImage(true); // Show the image once the card is processed
        }, 2000); // Simulate a delay for image loading
      })
      .catch((error) => {
       // alert(error.response.data.message)
       handleToastMessage(error.response.data.message);
        console.error("Error updating card:", error.response.data.message);
        setLoading(false); // Stop loading spinner in case of error
        if (error.status === "false") {
          handleToastMessage(error.response.data.message);
        }
      });
  };

  const handleKeyPress = (event) => {
    // Allow only numbers and prevent any other input
    const regex = /^[0-9]*$/;
    if (!regex.test(event.key)) {
      setError("Only numeric values are allowed.");
    } else {
      setError(""); // Clear error if valid input
    }

    if (event.key === "Enter") {
      handleCardUpload(); // Trigger the upload on Enter key press
    }
  };

  return (
    
    <Grid container spacing={1} size={12}>
      <Grid size={5} >
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />
        <div className={styles.detailsContainer}>
          <div className={styles.inlineField}>
            <p>
              <strong>Approval Date :</strong>
            </p>
            <div className={styles.valueBox}>{getTodayDate()}</div>
          </div>
          <div className={styles.inlineField}>
            <p>
              <strong>Approval By :</strong>
            </p>
            <div className={styles.valueBox}>Admin</div>
          </div>
          <div className={styles.inlineField}>
            <p>
              <strong>Assigned Date :</strong>
            </p>
            <div className={styles.valueBox}>{getTodayDate()}</div>
          </div>
        </div>
      </Grid>

      <Grid size={7} sx={{display:'flex', flexDirection:'row',justifyContent:'center', }}>
        {!loading && !showImage && (
          <div style={{display:'flex', flexDirection:'column',justifyContent:'center', }}>
            <TextField
              label="Enter Card Number"
              variant="outlined"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              onKeyPress={handleKeyPress}
              error={Boolean(error)}
              helperText={error}
              sx={{ width: "350px" }}
            />
            <Button
              onClick={handleCardUpload}
              variant="contained"
              sx={{ marginTop: "20px", width:'100px',marginLeft:'auto', marginRight:'auto' }}
            >
              Enter
            </Button>
          </div>
        )}

        {loading && (
          <>
            <CircularProgress />
            <p>Generating Card...</p>
          </>
        )}

        {showImage && (
          <div className={styles.cardImageContainer}>
            <img src={emptycard} alt="Card" className={styles.cardImage} />
            <div className={styles.cardNumber}>{cardNumber}</div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};


export default AssignCard;
