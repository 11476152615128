import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Link,
  Box,
} from "@mui/material";

const LoginPage = ({ setIsAuthenticated, setUsername }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Both email and password are required.");
      return;
    }

    setIsAuthenticated(true);
    setUsername("Admin");

    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("username", "Admin");

    navigate("/home");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      sx={{
        backgroundImage:
          "url(https://s3-alpha-sig.figma.com/img/d585/ada2/d574b2d0ec42d7635a5c29dec56f2596?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=phU2OCQ5XcmZ87xK3Q6np6PPNt5BXvjiSrAEyUJP888RBBCXhQTG85gOdZsJttD3JmwdRN3gnTnD06ueGq7m2w8rYNyD9lcdWRurmCiS5dx7QnA8II2-YDfCxY4L7gpdr6WJ3Z1kwaZ4PPvYcmkEcX7cp1POtAem9aUp3V5LNE6-7CaADrbVCyzubEGRmo-pdQjWN-v-eSQCS02hDSvTjyAHZVz8ZePvP7L3ZH~BYtKoJW6JliHZC1z125L~hENwtntoR2eGp04KveMgJZSuguLSxkdDk60BanLpK44oQ47V9V9MtkZTxPAqNY5KuGJy7ToHTD9fK4ziT-iqpbjuWQ__)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Card
        sx={{
          width: "400px",
          padding: "20px",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          boxShadow: 5,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Sign In
        </Typography>
        <CardContent>
          {error && (
            <Typography color="error" variant="body2" align="center">
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              required
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: "16px", padding: "10px", fontSize: "16px" }}
            >
              Sign In
            </Button>
            <Box display="flex" justifyContent="space-between" marginTop="16px">
              <Link href="#" underline="hover">
                Forgot password?
              </Link>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;