import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tablecomponent from "../../Components/Common/TableComponent/TableComponent";
import Title from "../../Components/Common/Title/Title";
import { Chip, CircularProgress } from "@mui/material";
import axios from 'axios';
import Snackbar from "@mui/material/Snackbar";

import { AlignHorizontalCenter } from "@mui/icons-material";
 
 
const UserOnboarding = () => {
 
 
  const navigate = useNavigate();
  const [userData, setUserData]=useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
 
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = <React.Fragment></React.Fragment>;

  const handleToastMessage = (messageText) => {
    setMessage(messageText);
    setOpen(true);
  };
 
  const columns = [
    { field: 'userName', headerName: 'User Name', flex: 1, headerClassName: 'super-app-theme--header' ,},
    { field: 'emailId', headerName: 'Email-ID', flex: 1, headerClassName: 'super-app-theme--header',   headerAlign: 'center',
      align: 'center',},
    {
      field: 'applicationStatus',
      headerName: 'Application Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{
            backgroundColor:
              params.value === 'AI Verification Failed'
                ? '#FFE0E5'
                : params.value === 'Rejected'
                ? '#FFE0E5'
                : params.value === 'In Progress'
                ? '#FFFFC9'
                : params.value === 'Verified'
                ? '#D9FBE7'
                : '#F1F1F1',
                color:
                params.value === 'AI Verification Failed'
                  ? '#FE718B'
                  : params.value === 'Rejected'
                  ? '#FE718B'
                  : params.value === 'In Progress'
                  ? '#FFA83D'
                  : params.value === 'Verified'
                  ? '#109A48'
                  : '#909090',
            borderColor: 'transparent',
          }}
          variant="outlined"
        />
      ),
    },
    { field: 'lastUpdate', headerName: 'Last Update', flex: 1, headerClassName: 'super-app-theme--header',  headerAlign: 'center',
      align: 'center', },
  ];
 
  useEffect(() => {
    setLoading(true);
    axios.get('https://bepay-prod.aifalabs.com/api/be-bot/onboarding', {
      headers: {
        'Content-Type': 'application/json',          
        'Custom-Header': 'value'                      
      }
    })
      .then(response => {
        setUserData(response.data.data);
        setLoading(false);
      })
      .catch(err => {
        setError('Failed to fetch FAQs');
        setLoading(false);
      });
  }, []);
 
  const rows = userData ? userData.map(user => ({
    id: user.kycdocumentid,
    userName: user.userName,
    emailId: user.email,
    applicationStatus: user.applicationStatus,
    lastUpdate: user.lastUpdate,
  })) : [];
 
  const handleRowClick = (params) => {
    console.log('Row clicked:', params.row);
    if(params.row.applicationStatus==='Application Incomplete' || params.row.applicationStatus==='In Complete'){
      handleToastMessage('Cannot open Appication Incomplete profile');
    }else{
      navigate(`/userOnboarding/${params.row.id}`/*, { state: { userData: params.row } }*/);
    }
  };
 
  return (
    <>
    <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />
      <Title title={'User Onboarding'} />
      <div style={{ margin: "30px" }}>
        {loading?<CircularProgress/>:<Tablecomponent rows={rows} columns={columns} onRowClick={handleRowClick} />}
      </div>
    </>
  );
};
 
export default UserOnboarding;