import React from 'react';
import style from './Analytics.module.css';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import TotalAnswereed from '../../../assets/TotalAnswered.svg';
import TotalUnAnswered from '../../../assets/TotalUnanswered.svg';
import TotalTicketsAnswered from '../../../assets/TotalTicketsAnswered.svg';
import TotalTicketsRaised from '../../../assets/TotalTicketsRaised.svg';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);


const Analytics = (props) => {
    const { totalAnswerd = 0, totalUnAnswerd = 0, totalTicketsRaised = 0, totalTicketsAnswered = 0 } = props.data || {};

    const pieData = {
        labels: [
            'Can I use this card to shop online?',
            'How to apply for the card?',
            'How to reload the card?',
            'Can I use this card to buy crypto?',
            'What are your opening hours?'
        ],
        datasets: [
            {
                data: [23, 2, 4, 2, 22],
                backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
            }
        ]
    };

    const barOptions = {
        responsive: true,
        plugins: {
            title: {
                display: false,  // This will hide the chart title
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };


    const barData = {
        
        labels: ['Tickets Raised', 'Tickets Answered'],
        datasets: [
          {
            label: 'Customer Support Tickets',
            data: [totalTicketsRaised, totalTicketsAnswered],
            backgroundColor: ['#36A2EB', '#9966FF'],
            barThickness: 60,
          },
        ],
      };

    const AnalyticsMiniCards = (props) => {
        return (

            <div className={style["faq-card-left"]}>
                <img src={props.imageSrc} />
                <div style={{marginLeft:'20px'}}>
                    <div className={style["card-title"]}>{props.title}</div>
                    <div className={style["card-value"]}>{props.number}</div>
                </div>
            </div>);
    };


    return (
        <div className={style["analytics-page"]}>

            <div className={style["faq-analytics"]}>
                <h3>FAQ's Analytics</h3>
                <div className={style["faq-container"]}>
                    <AnalyticsMiniCards title='Total Answered' number={totalAnswerd} imageSrc={TotalAnswereed} />
                    <AnalyticsMiniCards title='Total Unanswered' number={totalUnAnswerd} imageSrc={TotalUnAnswered} />
                </div>
                <h3>Top Triggered FAQ'S</h3>
                <div className={style["pie-container"]}>
                    <Pie data={pieData} />
                </div>
            </div>

            <div className={style["customer-support-analytics"]}>
                <h3>Customer Support Analytics</h3>
                <div className={style["support-container"]}>
                    <AnalyticsMiniCards title='Total Tickets Raised' number={totalTicketsRaised} imageSrc={TotalTicketsRaised} />
                    <AnalyticsMiniCards title='Total Tickets Answered' number={totalTicketsAnswered} imageSrc={TotalTicketsAnswered} />
                </div>
                <h3>Customer Support Tickets</h3>
                <div className={style["bar-container"]}>
                    <Bar data={barData} options={barOptions} />
                </div>
            </div>
        </div>
    );
};

export default Analytics;
