import React, { useEffect, useState } from "react";
import TabsContainer from "../../Components/Common/Tabs/Tabs"
import Tablecomponent from "../../Components/Common/TableComponent/TableComponent";
import Analytics from "./Analytics/Analytics";
import LLMs from "./LLMs/LLMs";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
 
 
const Home = () => {

  const [analyticsData, setAnalyticsData] = useState({
    analyticsSummary: {},
    costAnalytics: {},
  });
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState({});

  useEffect(() => {
      fetchAnalyticsData();    
  }, []);

  const fetchAnalyticsData = async () => {
    setIsLoadingAnalytics(true);
    try {
      const response = await fetch(`https://bepay-prod.aifalabs.com/api/be-bot/analytics`);
      if (!response.ok) {
        throw new Error("Failed to fetch analytics data");
      }

      const data = await response.json();
      setAnalyticsData(data.data || { analyticsSummary: {}, costAnalytics: {} });
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setIsLoadingAnalytics(false);
    }
  };




  const tabs = [
    { label: "Analytics", content: <Analytics data={analyticsData.analyticsSummary || {}} /> },
    { label: "LLM's", content: <LLMs data={analyticsData.costAnalytics || {}} /> },
    // { label: "Customer Support", content: <CustomerSupport /> },
  ];
  return (
    <TabsContainer tabs={tabs} />
  );
};
 
export default Home;
 