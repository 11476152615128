// httpService.js

import axios from 'axios';

const BASE_URL = 'https://bepay-prod.aifalabs.com/'; 


const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


async function httpRequest(url, method = 'GET', data = null) {
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error.response ? error.response.data : error.message; // Return error details
  }
}


export function get(url) {
  return httpRequest(url, 'GET');
}


export function post(url, data) {
  return httpRequest(url, 'POST', data);
}


export function put(url, data) {
  return httpRequest(url, 'PUT', data);
}


export function del(url) {
  return httpRequest(url, 'DELETE');
}

